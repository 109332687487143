import React from 'react';

import { graphql } from 'gatsby';
import HTMLReactParser from 'html-react-parser';

const PrivacyTemplate = (props) => {
  const {
    data: {
      page: {
        title,
        privacidade: { blocoDeTexto },
      },
    },
  } = props;

  return (
    <main>
      <section className="container container--xsmall">
        <h1>{title}</h1>
        {blocoDeTexto && HTMLReactParser(blocoDeTexto)}
      </section>
    </main>
  );
};

export const query = graphql`
  query Privacy($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      privacidade {
        blocoDeTexto
      }
    }
  }
`;

export default PrivacyTemplate;
